import { EnvironmentTargetInterface } from '@environments/assets/environment-target.interface';

export const contentApiTargets: EnvironmentTargetInterface = {
  build: {
    baseUrl: 'https://api.content.fagforbundet.build',
  },
  production: {
    baseUrl: 'https://api.content.fagforbundet.no',
  },
  staging: {
    baseUrl: 'https://api.content.fagforbundet.dev',
  },
};
