import { environment } from '@environments/environment';
import { createApiConfiguration, FfNgxEndpointDefinition } from '@fagforbundet/ngx-components';
import { contentApiEndpoints } from '@config/apis/content/content-api-endpoints';

export const contentApiConfig = createApiConfiguration<typeof contentApiEndpoints>({
  baseUrl: environment.apis.content.baseUrl,
  endpoints: contentApiEndpoints,
});


export const contentApiNoAuthEndpoints: FfNgxEndpointDefinition[] = [
  contentApiConfig.endpoints.POST_GRAPHQL,
];
